'use strict';

/*  Bit of a hack here to ensure common modules are bundled into the main.js file. If a module isn't
    included in ALL files, it will be bundled into the js file for each component and not in main.js, 
    making it re-load with each new route. Put any modules that you want included in the main.js file below. 
*/
import 'components/base/Header';
import 'components/base/Nav';
import 'components/base/Page';
import 'constants/appConstants';
// End 

import Loader from 'components/base/Loader';
import Modal from 'components/base/Modal';

import authActions from 'actions/AuthActionCreators';
import authStore from 'stores/AuthStore';
import ConfigOptionsManager from 'services/configOptionsManager';
import configOptionsStore from 'stores/ConfigOptionsStore';
import EvoButtonManager from 'services/evoButtonManager';
import permissionsActions from 'actions/PermissionsActionCreators';
import permissionsStore from 'stores/PermissionsStore';
import workflowActions from 'actions/WorkflowActionCreators';
import workflowStore from 'stores/WorkflowStore';
import PermissionsManager from 'services/permissions';
import personActions from 'actions/PersonActionCreators';
import personStore from 'stores/PersonStore';
import approvalsActions from 'actions/ApprovalsActionCreators';
import approvalsStore from 'stores/ApprovalsStore';
import appConstants from 'constants/appConstants';
import storageUtils from '../../services/storageUtils';

import modalActions from 'actions/ModalActionCreators';

import React from 'react';

import classNames from 'classnames';

import DocumentTitle from 'components/base/DocumentTitle';
import Header from 'components/base/Header';
import Nav from 'components/base/Nav';
import NotificationsModalContent from 'components/base/NotificationsModalContent';
import NavSlider from 'services/navSlider';
import ScrollManager from 'services/scrollManager';

import _ from 'underscore';
import 'services/watermark';

// CSS
import 'styles/TngApp.scss';

const pendo = window.pendo;

class TngApp extends React.Component {

    windowResizeTimer;
    
    state = {
        userInfo: authStore.getLoggedInUserData(),
        loading: false,
        approvalsData: null,//{approvals: null, error: null, loading: false},
        approvalsInReview: [],
        navSlider: new NavSlider(),
        isFull: true,
        companyLogo: {},
        permissionGetAttempts: 0,
        maxPermissionGetAttempts: 2,
        notifications: null,
        isNewLogin: false,
        pendoInitialized: false,
        intercomBooted: false,
        userIdP: storageUtils.getUserIdPFromStorage(),
    };

    componentWillMount() {
        authStore.addChangeListener(this._onChange);
        authStore.addLoginChangeListener(this._onLoginChange);
        permissionsStore.addChangeListener(this._onPermissionsChange);
        configOptionsStore.addChangeListener(this.onConfigurationOptionsChange);
        workflowStore.addChangeListener(this._onWorkflowChange);
        personStore.addChangeLoginPersonListener(this._onLoginPersonChange);
        personStore.addChangeCompanyLogoListener(this._onCompanyLogoChange);
        personStore.addSinglePersonNotificationsChangeListener(this._onNotificationsChange);
        approvalsStore.addChangeListener(this._onApprovalChange);
        approvalsStore.addApprovalActionResponseListener(this.handleApprovalActionResponse);
    }
    
    componentWillUnmount() {
        authStore.removeChangeListener(this._onChange);
        authStore.removeLoginChangeListener(this._onLoginChange);
        permissionsStore.removeChangeListener(this._onPermissionsChange);
        configOptionsStore.removeChangeListener(this.onConfigurationOptionsChange);
        workflowStore.removeChangeListener(this._onWorkflowChange);
        personStore.removeChangeLoginPersonListener(this._onLoginPersonChange);
        personStore.removeChangeCompanyLogoListener(this._onCompanyLogoChange);
        personStore.removeSinglePersonNotificationsChangeListener(this._onNotificationsChange);
        approvalsStore.removeChangeListener(this._onApprovalChange);
        approvalsStore.removeApprovalActionResponseListener(this.handleApprovalActionResponse);
        window.removeEventListener("resize", this.windowResized);
    }
    
    componentDidMount() {
        const currentUser = storageUtils.getUserFromStorage();
        if (!currentUser) {
            if (!this.props.main) {
                this.props.router.push({
                    pathname: '/signin/',
                });
            }
        } else {
            authActions.pageMounted();
            const email = currentUser.profile.email ? currentUser.profile.email : null;
            if (email !== null) {
                const migrationModel = {
                    sourceuserId: currentUser.profile.ess_user_id,
                    email, 
                };
                authActions.getMigrationUrl(migrationModel);
            }
        }
        window.addEventListener("resize", this.windowResized);
    }

    _onPermissionsChange = () => {
        var permissionsData = permissionsStore.getPermissions();
        this.handleLoader(permissionsData.loading);
        
        if (!permissionsData.loading) {
        
            if (permissionsData.permissions) {

                this.permissionsManager.setPermissions(permissionsData.permissions);
                this.setState({
                    permissions: this.permissionsManager,
                    permissionGetAttempts: 0,
                }, () => { 
                    if (this.state.isNewLogin
                        && !this.state.notifications
                    ) {
                        setTimeout(function () {
                            personActions.getNotifications();
                        }, 0);                          
                    }
                });                        
            } else {
                if (this.state.permissionGetAttempts < this.state.maxPermissionGetAttempts) {
                    setTimeout(function () {
                        permissionsActions.getPermissions();
                    }, 0);
                    this.setState({
                        permissionGetAttempts: this.state.permissionGetAttempts + 1,
                    });
                } else {
                    console.log('An error occured connecting to ESS');
                }
            }
        }
    };

    onConfigurationOptionsChange = () => {
        this.setState({
            configOptionsManager: this.configOptionsManager,
        });
    };

    _onWorkflowChange = () => {
        var workflowScopeData = workflowStore.getUserWorkflowScopesData();
        this.handleLoader(workflowScopeData.loading);
        if (!workflowScopeData.loading) {
            this.setState({
                workflowScopes: workflowScopeData.userWorkflowScopes,
            });                        
        }
    };

    _onChange = () => {
        const userInfo = authStore.getLoggedInUserData();
        const migrationUrl = authStore.getMigrationUrl();
        userInfo.mounted = true;
        userInfo.isNewStarter = false;
        let customData;
        var workflowitemid = -1; 
        const delay = 2000;       

        if (userInfo.signInError) {
            this.props.router.push({
                pathname: '/signinerror/',
            });            
        } else {
            if (userInfo.user && userInfo?.user?.profile?.profile?.custom_data) {
                customData = JSON.parse(userInfo?.user?.profile?.profile?.custom_data);
                workflowitemid = customData?.WorkflowItemId;
            }	

            if (userInfo.user && userInfo.user.profile && workflowitemid > 0) {
                userInfo.isNewStarter = true;
            }

            if (userInfo.isLoggedIn) {
                if (migrationUrl && !migrationUrl.loading && !migrationUrl.error && migrationUrl.migrationUrl) {
                    window.location.href = migrationUrl.migrationUrl;
                } else {
                    this.permissionsManager = new PermissionsManager();
                    this.configOptionsManager = new ConfigOptionsManager();
                    this.evoButtonManager = new EvoButtonManager();
                    setTimeout( () => {
                        permissionsActions.getPermissions();
                        workflowActions.getUserWorkflowScopes();
                        this.evoButtonManager.initialise();
                        
                        this.setState({
                            isAccessIdUser: this.evoButtonManager?.getIsAccessIdUser(),
                        });
                        if (userInfo.user.profile) {       
                            personActions.loginPerson();         
                            personActions.getLoginPerson();
                            personActions.getCompanyLogo();      
                        }
                            
                        if (!userInfo.isNewStarter) {
                            const scrollManager = new ScrollManager(this.onScrollChange, this.state.isFull);
                            scrollManager.start(this.onScrollChange);
                        }
                    }, delay);
                }
            } else {
                if (!this.props.main) {
                    this.handleLogin();
                }
            } 

            this.setState({
                userInfo,
            });
        }
    };

    _onLoginChange = () => {
        /* if we end up here we know it's a new login, rather than just a refresh 
            add any logic that relies on knowing it's a new login here */

        this.setState({
            isNewLogin: true,
        });
    };

    _onLoginPersonChange = () => {
        var _userInfo = this.state.userInfo;
        _userInfo.person = personStore.getLoggedInPersonData();

        var tenantId = "";

        if(_userInfo?.user?.profile?.profile?.custom_data){
            var customData = JSON.parse(_userInfo?.user?.profile?.profile?.custom_data);
            tenantId = customData?.Tenant;
        }
        else{
            tenantId = storageUtils.getValueFromStorage('X-tid');
        }        

        if (pendo && _userInfo.person && !this.state.pendoInitialized) {
            pendo.initialize({
                visitor: {
                    id: _userInfo.person.Id,
                },
                account: {
                    id: tenantId,
                    companyName: _userInfo.person.CompanyName,
                },
            });

            if (pendo.isReady && pendo.isReady()) {
                this.setState({pendoInitialized: true});
            }          
        }

        if (appConstants.intercomKey && window.Intercom && !this.state.intercomBooted
            && _userInfo.person && _userInfo.person.AdminRole && _userInfo.person.AdminRole.RoleType === appConstants.ENUMS.ROLE_TYPES.ADMINISTRATOR) {
            window.Intercom('boot', {
                app_id: appConstants.intercomKey,
                type: _userInfo.person.AdminRole.RoleType,
                email: _userInfo.user.profile.profile.emails[0],
                user_id: _userInfo.person.Id,
                account: tenantId,
                accountName: _userInfo.person.CompanyName,
                created_at: _userInfo.person.AdminRole.CreatedDate,
            });
            this.setState({intercomBooted: true});
        }

        this.setState({
            userInfo: _userInfo,
        }, function () {
            if (_userInfo.person) {
                setTimeout( () => {
                    this.configOptionsManager.getOptions();
                }, 0);
                
                if (_userInfo.person.ApproverTypes && _userInfo.person.ApproverTypes.length > 0 && this.state.approvalsData === null) {
                    setTimeout(function () {
                        approvalsActions.getApprovals();
                    }, 0);      
                }  
            } 
        }.bind(this));
    };

    _onNotificationsChange = () => {
        const notifications = personStore.getNotifications();
        this.setState({notifications});
        
        if (notifications && !notifications.loading && !notifications.error && notifications.notifications.length > 0) {

            const splitNotifications = {
                inline: [],
                admin: [],
            };

            notifications.notifications.forEach(element => {
                if (element.DisplayType === "admin") {
                    splitNotifications.admin.push(element);
                } else if (element.DisplayType === "inline") {
                    splitNotifications.inline.push(element);
                }
            });
            
            this.setState({
                inlineNotifications: splitNotifications.inline,
            });

            if (splitNotifications.admin.length > 0) {
                setTimeout(() => {
                    modalActions.showModal(
                        <NotificationsModalContent
                            notifications={splitNotifications.admin}
                        />
                    );
                }, 0);
            }
        }            
    };

    _onCompanyLogoChange = () => {
        const companyLogoInfo = personStore.getCompanyLogoData();
        if (companyLogoInfo.logo && typeof(companyLogoInfo.logo) !== 'string') {
            this.setState({
                companyLogo: {
                    loading: false,
                    logo: null,
                },
            });
        } else {
            this.setState({
                companyLogo: companyLogoInfo,
            });            
        }
    };

    _onApprovalChange = () => {

        var approvalsData = approvalsStore.getApprovals();
        
        if ((approvalsData.loading || approvalsData.error || !_.isEmpty(this.state.approvalsInReview)) && this.state.approvalsData && this.state.approvalsData.approvals ) {
            approvalsData.approvals =  this.state.approvalsData.approvals.slice();
        }

        this.setState({
            approvalsData,
            handleApproval: this.handleApproval,
        });
        
    };

    onScrollChange = (isFull) => {
        this.setState({isFull});
    };

    handleApproval = (reviewedApprovals, isApproved) => {
        
        approvalsStore.resetApprovalActionResponseQueue();

        if (!_.isArray(reviewedApprovals)) {
            reviewedApprovals = [reviewedApprovals];
        }

        if (this.state.approvalsData && this.state.approvalsData.approvals) {

            var currentPendingApprovals = this.state.approvalsData.approvals.slice();
            _.each(reviewedApprovals, function (reviewedApproval) {

                switch (isApproved) {
                    case true:
                        approvalsActions.approve(reviewedApproval);
                        break;
                    case false:
                        approvalsActions.decline(reviewedApproval);
                        break;
                } 

                currentPendingApprovals = _.reject(currentPendingApprovals, function (approval) {
                    return  approval.WorkflowItemId && approval.WorkflowItemId === reviewedApproval.WorkflowItemId;
                });

            }.bind(this));

            var approvalsDataCopy = {};
            _.extendOwn(approvalsDataCopy, this.state.approvalsData);
            approvalsDataCopy.approvals = currentPendingApprovals.slice();
            approvalsDataCopy.approvalsErrors = []; 
                
            this.setState({
                approvalsData: approvalsDataCopy,
                approvalsInReview: reviewedApprovals,
            });
        }   
    };

    handleApprovalActionResponse = () => {
        
        if (_.isEmpty(this.state.approvalsInReview)) {
            return;
        }

        var queue = approvalsStore.getApprovalActionResponseQueue();
        var approvalsInReview = this.state.approvalsInReview.slice();

        const approvalsErrors = [];

        _.each(queue.approvalActionResponseQueue, function (item) {
            if (item.error) {
                approvalsErrors.push(item);
            }

            approvalsInReview = _.reject(approvalsInReview, function (approval) {
                return approval.WorkflowItemId && approval.WorkflowItemId === item.workflowItemId && !item.loading;
            });
        }.bind(this));
        
        // if no more approvals in review, then make ajax call to get all approvals
        if (_.isEmpty(approvalsInReview)) {
            setTimeout(function () {
                approvalsActions.getApprovals();
            }, 0);
        }

        this.setState({
            approvalsInReview,
            approvalsData: {
                approvalsErrors,
                approvals: [],
            },
        });
    };

    handleLoader = (loading) => {
        if (this.state.loading !== loading) {
            this.setState({
                loading,
            });
        }
    };

    handleLogout = () => {
        this.setState({
            loading: true,
        });        
        if (appConstants.intercomKey  && window.Intercom) {
            window.Intercom('shutdown');
        }
        authActions.logout();
    };

    handleLogin = () => {
        this.setState({
            loading: true,
        });
        authActions.login(this.state.userIdP);
    };

    getApprovals = () => {
        approvalsActions.getApprovals();
    }

    windowResized = () => {
        // only action after the resize has stopped for 200ms

        if (this.windowResizeTimer) {
            clearTimeout(this.windowResizeTimer);
        }
        if (this.evoButtonManager) {
            this.windowResizeTimer = setTimeout( () => {
                this.evoButtonManager.resize();
                this.setState({
                    isAccessIdUser: this.evoButtonManager?.getIsAccessIdUser(),
                });
            }, 200);
        }
    }

    render() {

        var isNude = this.props.main !== undefined; 

        var classes = classNames('main', {
            'nude': isNude,
            'full': !isNude,
            'new-starter': this.state.userInfo.isNewStarter,
            'evo': this.state.isAccessIdUser,
        });

        var mainPage;

        if (isNude) {
            mainPage = this.props.main;
        } else {
            mainPage = this.props.children;
        }
        
        let showPage = true;

        if (this.state.userInfo.isLoggedIn) {
            if (this.state.permissions) {
                if (!this.state.userInfo.person) {
                    showPage = false;   
                }
            } else {
                showPage = false;
            }
         
        }
        
        return (
            <DocumentTitle>
                <div>
                    <Loader loading={this.state.loading} />
                    <Modal />
                    <section id="page-container" className={classes}>
                        
                        <Header
                            {...this.props}
                            userInfo={this.state.userInfo}
                            handleLogin={this.handleLogin}
                            navSlider={this.state.navSlider}
                            isNewStarter={this.state.userInfo.isNewStarter}
                            companyLogo={this.state.companyLogo}
                            permissions={this.state.permissions}
                            configOptionsManager={this.state.configOptionsManager}
                        />

                        <Nav
                            {...this.props}
                            location={this.props.location}
                            handleLogout={this.handleLogout}
                            handleLogin={this.handleLogin}
                            userInfo={this.state.userInfo}
                            nude={isNude}
                            permissions={this.state.permissions}
                            configOptionsManager={this.state.configOptionsManager}
                            navSlider={this.state.navSlider}
                            isNewStarter={this.state.userInfo.isNewStarter}
                            isFull={this.state.isFull}
                            companyLogo={this.state.companyLogo}
                        />

                        {showPage ?  (
                            React.cloneElement(mainPage, {
                                handleLogout: this.handleLogout,
                                handleLogin: this.handleLogin,
                                userInfo: this.state.userInfo,
                                permissions: this.state.permissions,
                                configOptionsManager: this.state.configOptionsManager,
                                workflowScopes: this.state.workflowScopes,
                                handleLoader: this.handleLoader,
                                approvalsData: this.state.approvalsData, 
                                handleApproval: this.state.handleApproval,
                                getApprovals: this.getApprovals,
                                navSlider: this.state.navSlider,
                                isNewStarter: this.state.userInfo.isNewStarter,
                                isFull: this.state.isFull,
                                inlineNotifications: this.state.inlineNotifications,
                            })
                        ) : null}
                    </section>
                    <div id="evo-mobile-container"></div>
                </div>
            </DocumentTitle>
        );
    }
}

export default TngApp;
