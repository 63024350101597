'use strict';

import authActions from 'actions/AuthActionCreators';
import { UserManager, WebStorageStateStore, Log } from 'oidc-client-ts';
import storageUtils from "services/storageUtils";
import appConstants from '../constants/appConstants';

oidcEnableLogging();

const tokenRenewalOffsetSeconds = appConstants.auth.TOKEN_RENEWAL_OFFSET_SECONDS || 600;
let token = null;
let signedIn = false;
let wasLastTokenRetrievalSuccessful = false;
let inProgress = false;
let isNewSignIn = false;
let currentUser = "";

const commonConfig = {
    redirect_uri: appConstants.auth.OIDC_REDIRECT_URI,  
    post_logout_redirect_uri: appConstants.auth.OIDC_POST_LOGOUT_REDIRECT_URI,
    response_type: appConstants.auth.OIDC_RESPONSE_TYPE,
    response_mode: appConstants.auth.OIDC_RESPONSE_MODE,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

const accessIdPConfig = {    
    client_id: appConstants.auth.ACCESSIDP_CLIENT_ID, 
    authority:  appConstants.auth.ACCESSIDP_AUTHORITY, 
    knownAuthorities: [appConstants.auth.ACCESSIDP_KNOWN_AUTHORITIES], 
    scope: appConstants.auth.ACCESSIDP_SCOPES,  
    loadUserInfo : true,
    ...commonConfig,
};

const b2CConfig = {    
    client_id: appConstants.auth.B2C_CLIENT_ID,
    authority:  appConstants.auth.B2C_SIGNIN_AUTHORITY,    
    scope: "openid",
    silent_redirect_uri: window.location.origin + '/silent_renew.html',
    ...commonConfig,
};

var userManagerInstance = userManagerInstance || new UserManager(getUserIdPConfig());

function oidcEnableLogging(level = appConstants.auth.OIDC_LOG_LEVEL) {
    switch (level) {
        case 'DEBUG':
            Log.setLevel(Log.DEBUG);
            break;
        case 'INFO':
            Log.setLevel(Log.INFO);
            break;
        case 'WARN':
            Log.setLevel(Log.WARN);
            break;
        case 'ERROR':
            Log.setLevel(Log.ERROR);
            break;
        default:
            Log.setLevel(Log.NONE);  // Default to NONE if no valid level is set
    }
    Log.setLogger(console);
}

function selectCachedUser() {
    currentUser = storageUtils.getUserFromStorage();
    if (!currentUser) {        
        console.log("No User");
        inProgress = true;
        return;
    }
}

function getClaims(email) {    
    var url = "{0}/getUserDetails/{1}".format(appConstants.api_urls.ACTIVATION, email);
    fetch(url, {
        method: 'GET',
    })
        .then((response) => response.json())
        .then((responseJson) => {
            storageUtils.setValueToStorage('X-tid', responseJson?.Item1);
            storageUtils.setValueToStorage('X-uid', responseJson?.Item2);
        })
        .catch((error) => {
            console.error(error);
        });
}

function handleResponse(response, isRenew = false) {
    if (!isRenew && inProgress && response) { // is a new sign in
        isNewSignIn = true;
    }
    inProgress = false;   

    if (response !== null) {       
        if (response.access_token && response.access_token !== "") {
            currentUser = response;
            storageUtils.setUserToStorage(currentUser);
            token = response.access_token;
            const parts = token.split('.');
            const decodedPayload = atob(parts[1]);
            const parsedPayload = JSON.parse(decodedPayload);
            const email = parsedPayload.email;
            if (storageUtils.getUserIdPFromStorage() === appConstants.ENUMS.IDENTITY_PROVIDER.ACCESS && 
            (storageUtils.getValueFromStorage('X-tid') === null || storageUtils.getValueFromStorage('X-uid') === null)) {
                getClaims(email);
            }
        }
    } 
        
    if (response === null && signedIn) { 
        return;
    } else {
        selectCachedUser();         
        if (!isRenew) {            
            authActions.pageReady(isNewSignIn);
            isNewSignIn = false;
        }
    } 
}

function handleSignInError() {
    authActions.signInError();
}

function checkForToken() {
    selectCachedUser();
    if (currentUser && !token) {
        signedIn = true;
        getTokenRedirect();
    } else {
        signedIn = false;
    }    
}

function getCurrentUser() {
    if (currentUser) {
        return currentUser;
    }
    return;    
}

function login() {
    var idPConfig = getUserIdPConfig();
    userManagerInstance = new UserManager(idPConfig);
    userManagerInstance.signinRedirect();
}

function logOut() {
    storageUtils.clearStorage();
    userManagerInstance.signoutRedirect ({
        id_token_hint: currentUser?.id_token,
    });
}

function signUp(token = null) {
    // Implement signup flow 
}

function isTokenExpiringSoon(token, bufferTimeInSeconds = 60) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpiryTime  = decodedToken.exp;
    const remainingTime = tokenExpiryTime - currentTime;

    return remainingTime <= bufferTimeInSeconds;
}

function getTokenRedirect(isRenew = false) {
    const currentUser = storageUtils.getUserFromStorage();
    
    if (!currentUser) {
        console.log("No user is currently logged in.");
        return;
    }

    if (currentUser) {
        var tokenExpiring = isTokenExpiringSoon(currentUser.access_token);
        if (tokenExpiring) {
            console.log("Token is expiring, attempting silent sign-in...");
            try {
                const signinSilentArgs = {
                    redirect_uri: commonConfig.redirect_uri,
                };
                userManagerInstance.signinSilent(signinSilentArgs)
                    .then(response => {
                        console.log("Token successfully refreshed:", response);
                        wasLastTokenRetrievalSuccessful = true;
                        return handleResponse(response, isRenew);
                    })
                    .catch(error => {
                        console.error('Failed to renew ID token:', error);
                        if (error.message.includes('login_required')) {
                        // Handle the timeout, prompting the user to re-authenticate
                            userManagerInstance.signinRedirect();
                        }
                    });
            } catch (error) {
                console.error("Silent sign-in failed:", error);
                wasLastTokenRetrievalSuccessful = false;
            }
        } else {
            console.log("Token is still valid.");
            wasLastTokenRetrievalSuccessful = true;
            return handleResponse(currentUser, isRenew);
        }
    }
}

function getToken() {
    if (!wasLastTokenRetrievalSuccessful) {
        getTokenRedirect(true);
    } else {
        try {
            return token;
        } catch (error) {
            console.log(error);
        }
    }
}

function getUserIdPConfig(userIdP = storageUtils.getUserIdPFromStorage()) {
    if (userIdP === "" || userIdP === undefined || userIdP === null) {
        console.error("No User IdP found");
        return b2CConfig;
    } else if (userIdP === appConstants.ENUMS.IDENTITY_PROVIDER.ACCESS) {
        return accessIdPConfig;
    } else {
        return b2CConfig;
    }
}

setInterval(function () {
    getTokenRedirect(true);
}.bind(this), tokenRenewalOffsetSeconds * 1000);

export { login, logOut, signUp, getTokenRedirect, getCurrentUser, checkForToken, handleResponse, getToken, getUserIdPConfig, oidcEnableLogging };